import invariant from 'invariant'
import React from 'react'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { Texts as AppTexts } from '../../../../pure/libs/AppTexts'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { bigSpacing } from '../enums/Spacings'
import { useVitecNOOrder } from '../hooks/QueryHooks'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import { getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import { LoadingPage } from './LoadingPage'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { VitecNextOrderProductLongShortDescriptionItem } from './VitecNextOrderProductLongShortDescriptionItem'

export const VitecNextOrderProductLongShortDescription: React.FC<VitecNextOrderProductProps> = (props) => {
  const { vitecNextOrderId } = props
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)

  if (isLoading) return <LoadingPage />

  const e = vitecNextOrder?.jaguarResponses?.SHORT_LONG_DESCRIPTION?.extra

  if (!e) return <LoadingPage />

  invariant(e, 'Missing data for SHORT_LONG_DESCRIPTIONon vitecNOOrderId: %s', vitecNextOrder.orderId)

  invariant(e.longDescription, 'Missing longDescription for vitecNOOrderId: %s', vitecNextOrder.orderId)
  invariant(e.shortDescription, 'Missing shortDescription for vitecNOOrderId: %s', vitecNextOrder.orderId)

  return (
    <Box fullWidth>
      <Box fullWidth fullPadding direction="row" justify="space-between">
        <Box>
          <VitecNextOrderProductBackButton {...props} />
        </Box>
        <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
          <VitecNextOrderProductHeader
            vitecNextOrder={vitecNextOrder}
            headerTextKey={AppTexts.longShortHeader}
            vitecNextProduct={VitecNextProduct.SHORT_LONG_DESCRIPTION}
          />
          <Box fullWidth top>
            <VitecNextOrderProductLongShortDescriptionItem
              header={getFigmaText(AppTexts.longShortShortHeadline)}
              description={e.shortDescription}
            />
          </Box>

          <Box fullWidth top spacing={bigSpacing}>
            <VitecNextOrderProductLongShortDescriptionItem header="Kort om eiendomen" description={e.longDescription} />
          </Box>
          <Box fullWidth top spacing={bigSpacing}></Box>
        </Box>
        <Box />
      </Box>
    </Box>
  )
}
