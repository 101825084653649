import Colors from '../assets/colors.json'

export const SecondaryColor = '#0052B4'

export const Yellow = '#F1DE7C'
export const LightYellow = '#FFFADE'

export const LightGrey = '#E3E3E3'

export default Colors
