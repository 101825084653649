import { FeatureFlag, FeatureFlagEnvironment } from '../../../pure/enums/FeatureFlags.js'
import { SEVEN_MINUTES_IN_SECONDS } from '../../../pure/libs/Consts.js'
import { getFeatureFlagsFromUrl } from './libs/Common.js'

export type ConfigType = typeof defaultConfig

type PartialConfig = Partial<typeof defaultConfig>

let env: string | undefined = (import.meta as any).env?.VITE_ENVIRONMENT

if (process.env.NODE_ENV === 'test') env = 'test'
if (!env) env = 'prod'

export const defaultConfig = {
  // Config
  name: 'default' as FeatureFlagEnvironment,
  appUrl: 'http://localhost:5173',
  jaguarUrlDeprecated: 'https://jaguar-wu7ao43iaa-lz.a.run.app',
  faqUrl: 'https://faq-e8d702.webflow.io',
  sentryDsn: 'https://7ebdc9ea88a244deb112a70ee372ed16@o4505027564339200.ingest.sentry.io/4505027568140288',
  slackWebHookUrl: 'https://hooks.slack.com/services/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',
  jaguarExperimentalUrl: 'https://experimental---jaguar-wu7ao43iaa-lz.a.run.app',
  leopardOrderSendEmailTimeoutSeconds: 30,
  longOrderTimeoutSeconds: SEVEN_MINUTES_IN_SECONDS,

  // Features
  enableLogFirebaseDBError: false,
  enableLogFirestoreQueryResult: false,
  enableLoggingOfConfig: false,
  enableLoggedInSandbox: false,
  enableErrorMessagesInNotifications: false,
  enableSendgrid: true,
  enableSentry: false,
  enableServiceUnavilablePage: false,
  enableGooglePlace: false,
  enableLogAdInJaguar: true,
  enableNOLanguage: true,

  enableValidateAdProduct: false,
  enableImageCaptionsProduct: true,
  enableByggemateProduct: true,
  enableLongAndShortProduct: true,
  [FeatureFlag.enableBasicAssociation]: false
}

export const local: PartialConfig = {
  ...defaultConfig,
  name: 'local' as FeatureFlagEnvironment,
  enableValidateAdProduct: false,
  [FeatureFlag.enableBasicAssociation]: true
}

const test: PartialConfig = {
  ...defaultConfig,
  name: 'test' as FeatureFlagEnvironment,
  enableLogFirebaseDBError: false
}

export const prod: PartialConfig = {
  ...defaultConfig,
  appUrl: 'https://app.gazelle.work',
  name: 'prod' as FeatureFlagEnvironment,
  enableLogFirestoreQueryResult: false,
  enableSentry: true,
  enableGooglePlace: true
}

const configs = { local, prod, test }

let config = configs[env] || prod

config = { ...config, ...getFeatureFlagsFromUrl() }

// eslint-disable-next-line no-console
if (config.enableLoggingOfConfig) console.log('Running app with config', config)

export default config as typeof defaultConfig
