import dayjs from 'dayjs'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItemLoadingProps } from '../types/VitecNextProductTypes'
import { VitecNextOrderItemStatus } from './VitecNextOrderItemStatus'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

export const VitecNextOrderItemStatusLongAndShortDescription = (props: VitecNextProductInfoItemLoadingProps) => {
  const { isProductEnabled, now } = props
  const { data: vitecNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.SHORT_LONG_DESCRIPTION
  const { data: upload } = useUpload(leopardOrder?.requestId)

  if (!vitecNextOrder) return null

  // UX feature
  if (!upload && !!leopardOrder && dayjs(now).diff(dayjs(leopardOrder?.createdAt), 'seconds') < 30)
    return <VitecNextOrderItemStatusView type="processing" textKey={Texts.standardNotificationInProgress} />

  return (
    <VitecNextOrderItemStatus
      {...props}
      upload={upload}
      vitecNextOrder={vitecNextOrder}
      vitecNextProduct={VitecNextProduct.SHORT_LONG_DESCRIPTION}
      isProductEnabled={isProductEnabled}
    />
  )
}
