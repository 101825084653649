import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import Colors from '../../../../pure/libs/Colors'
import { UploadedFile } from '../../../../pure/types/JaguarTypes'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, mediumSpacing, smallSpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import { Checkbox } from './Checkbox'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { JsonView } from './JsonView'
import { LoadingPage } from './LoadingPage'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtons } from './VitecNextOrderProductButtons'
import { VitecNextOrderProductFeedbackButton } from './VitecNextOrderProductFeedbackButton'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'

type BasicAssociationFileItem = {
  input: any
  status: string // "done",
  validatedInput: number | string // 1650,
  documentUrls: Array<string | null>
  confidence: number // 1
}

type Field = BasicAssociationFileItem & { checked?: boolean }

type Fields = {
  [key: string]: Field
}

export const VitecNextOrderProductBasicAssociation = (props: VitecNextOrderProductProps) => {
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]

  const { data: upload } = useUpload(leopardOrder?.requestId || '')
  const { data: uploadedFiles = [] } = useUploadedFiles(leopardOrder?.requestId || '')

  const [fields, setFields] = useState<Fields>({})

  useEffect(() => {
    if (uploadedFiles.length === 0) return
    setFields(
      Object.entries(uploadedFiles[0]?.PropertyAdditionalInfo || {}).reduce(
        (a, [key, v]) => ({ ...a, [key]: v as unknown as Field }),
        {}
      )
    )
  }, [uploadedFiles.length])

  if (isLoading) return <LoadingPage />
  if (!vitecNextOrder) return <Box />

  const onClickChecked = (checked: boolean, key: string, fields: Fields) =>
    setFields({ ...fields, [key]: { ...fields[key], checked } })

  return (
    <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
      <Box>
        <VitecNextOrderProductBackButton {...props} />
      </Box>
      <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
        <VitecNextOrderProductHeader
          vitecNextOrder={vitecNextOrder}
          headerTextKey={Texts.BasicAssociationBaHeaderText}
          vitecNextProduct={VitecNextProduct.BASIC_ASSOCIATION}
        />
        <Box top>
          <FigmaImageContainer imageKey={Images.gazelleInCircle} />
        </Box>
        <Box fullWidth top spacing={smallSpacing}>
          <VitecNextOrderProductButtons
            vitecNextOrder={vitecNextOrder}
            vitecNextProduct={VitecNextProduct.BASIC_ASSOCIATION}
            fulfillMentSuccessText={getFigmaText(Texts.standardNotificationUploadComplete)}
          />
        </Box>
        <Box top spacing={smallSpacing} />
        {upload?.status === 'processing' && <LoadingPage />}
        {upload?.status === 'done' && (
          <Container fullWidth fullPadding>
            <Box direction="row" align="center">
              <FigmaText textKey={Texts.ConstructionCiTextHeader} />
            </Box>
            <Box top>
              <VitecNextOrderProductFeedbackButton {...props} />
            </Box>
            {Object.entries(fields).map(([k, v]) => {
              const checked = v.checked || true
              return (
                <Box>
                  <Box top direction="row">
                    <Box onClick={() => onClickChecked(!checked, k, fields)} pointer>
                      <Checkbox value={checked} />
                    </Box>
                    <Box left spacing={smallSpacing}>
                      <FigmaTextWithStyle type="h7" text={k} />
                      <Box>
                        <FigmaText textKey={Texts.BasicAssociationBaCssFieldText} text={v.validatedInput?.toString()} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            })}

            <Box fullWidth top>
              <JsonView data={uploadedFiles} />
            </Box>
          </Container>
        )}
      </Box>
      <Box />
    </Box>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`

export function getHeaderAndDescriptions(uploadedFiles: UploadedFile[]) {
  return uploadedFiles
    .reduce(
      (a, file) =>
        a.concat(
          Object.entries(file.PropertyAdditionalInfo || {}).map(([k, v]) => ({
            header: k,
            description: v as string,
            file
          }))
        ),
      [] as any[]
    )
    .sort((a, b) => a.header.localeCompare(b.header))
}
