import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import config from '../Config'
import Images from '../libs/Images'
import { createLeopardOrder } from '../libs/ProcessHandler'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import { VitecNextOrderItemStatusBasicAssociation } from './VitecNextOrderItemStatusBasicAssociation'
import { VitecNextOrderItemStatusByggemate } from './VitecNextOrderItemStatusByggemate'
import { VitecNextOrderItemStatusImageCaptions } from './VitecNextOrderItemStatusImageCaptions'
import { VitecNextOrderItemStatusLongAndShortDescription } from './VitecNextOrderItemStatusLongAndShortDescription'
import { VitecNextOrderProductBasicAssociation } from './VitecNextOrderProductBasicAssociation'
import { getHeaderAndDescriptions, VitecNextOrderProductContructionInfo } from './VitecNextOrderProductContructionInfo'
import { VitecNextOrderProductImageCaptions } from './VitecNextOrderProductImageCaptions'
import { VitecNextOrderProductLongShortDescription } from './VitecNextOrderProductLongShortDescription'

const vitecNextConstructionInfo: VitecNextProductInfoItem = {
  textKey: Texts.dashboardConstructionInfoTitle,
  descriptionKey: Texts.dashboardConstructionInfoDescr,
  imageKey: Images.constructionInfoIcon,
  slug: 'byggemate',
  PreviewComponent: VitecNextOrderProductContructionInfo,
  StatusComponent: VitecNextOrderItemStatusByggemate,
  buttons: [
    {
      buttonTextKey: Texts.dashboardCtaGenerate,
      onClick: createLeopardOrder
    }
  ],
  getText: ({ uploadedFiles = [] }) =>
    getHeaderAndDescriptions(uploadedFiles).reduce(
      (acc, { header, description }) => `${acc}${header}\n${description}\n\n`,
      ''
    ),
  getJSON: ({ uploadedFiles = [] }) =>
    uploadedFiles
      .filter((f) => f.PropertyAdditionalInfo.ByggemåteStructured)
      .reduce((a, f) => {
        return {
          ...a,
          ByggemåteStructured: JSON.parse(f.PropertyAdditionalInfo.ByggemåteStructured || {}),
          TilstandsgradStructured: JSON.parse(f.PropertyAdditionalInfo?.TilstandsgradStructured || {})
        }
      }, {})
}

export const VitecNextProductInfoItems: {
  [property in VitecNextProduct]: VitecNextProductInfoItem
} = {
  [VitecNextProduct.IMAGE_CAPTIONS]: {
    textKey: Texts.dashboardAutoCaptioningTitleCaptioningTitleg,
    descriptionKey: Texts.dashboardAutoCaptioningDescr,
    imageKey: Images.imageCaptionIcon,
    slug: 'bildetekst',
    PreviewComponent: VitecNextOrderProductImageCaptions,
    StatusComponent: VitecNextOrderItemStatusImageCaptions,
    buttons: [
      {
        buttonTextKey: Texts.ImageTextsCtaGenImageTexts,
        getButtonText: (r?: IsVitecNextProductEnabledResponse) =>
          formatText(getFigmaText(Texts.ImageTextsCtaGenImageTexts), [r?.noOfDocuments || '0']),
        onClick: createLeopardOrder
      }
    ]
  },
  [VitecNextProduct.BYGGEMÅTE_DEPRECATED]: vitecNextConstructionInfo,
  [VitecNextProduct.CONSTRUCTION_INFO]: vitecNextConstructionInfo,
  [VitecNextProduct.CONSTRUCTION_INFO_EXPERIMENTAL]: {
    ...vitecNextConstructionInfo,
    enableExperimentalLabelInPreview: true,
    opts: {
      jaguarUrl: config.jaguarExperimentalUrl
    }
  },
  [VitecNextProduct.SHORT_LONG_DESCRIPTION]: {
    textKey: Texts.dashboardShortLongTitle,
    descriptionKey: Texts.dashboardShortLongDescr,
    imageKey: Images.headerAndShortDescriptionIcon,
    slug: 'short-and-long',
    PreviewComponent: VitecNextOrderProductLongShortDescription,
    StatusComponent: VitecNextOrderItemStatusLongAndShortDescription,
    buttons: [
      {
        buttonTextKey: Texts.ImageTextsSelectPinpointText,
        onClick: (props) =>
          createLeopardOrder({ ...props, extra: { ...props.extra, longShortDescriptionType: 'bullets' } })
      },
      {
        buttonTextKey: Texts.ImageTextsSelectFloatingText,
        onClick: (props) =>
          createLeopardOrder({ ...props, extra: { ...props.extra, longShortDescriptionType: 'long' } })
      }
    ]
  },
  [VitecNextProduct.VALIDATE_AD]: {
    textKey: Texts.dashboardAdValidationTitle,
    descriptionKey: Texts.dashboardAdValidationDescr,
    imageKey: Images.adValidationIcon,
    slug: 'ad-validation',
    buttons: [{ buttonTextKey: Texts.dashboardCtaGenerate, onClick: createLeopardOrder }]
  },
  [VitecNextProduct.BASIC_ASSOCIATION]: {
    textKey: Texts.dashboardBasicAssociationTitle,
    descriptionKey: Texts.dashboardBasicAssociationDesc,
    imageKey: Images.basicAssiciationIcon,
    slug: 'basic-association',
    PreviewComponent: VitecNextOrderProductBasicAssociation,
    StatusComponent: VitecNextOrderItemStatusBasicAssociation,
    buttons: [
      {
        buttonTextKey: Texts.dashboardCtaGenerate,
        onClick: createLeopardOrder
      }
    ],
    getText: ({ uploadedFiles = [] }) =>
      getHeaderAndDescriptions(uploadedFiles).reduce(
        (acc, { header, description }) => `${acc}${header}\n${description}\n\n`,
        ''
      )
  }
}
