import styled from '@emotion/styled'
import React from 'react'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { shouldShowLongWaitMessage } from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import Colors from '../../../../pure/libs/Colors'
import { createUniqueId } from '../../../../pure/libs/Common'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import useAppState from '../hooks/useAppState'
import useAppStateSession from '../hooks/useAppStateSession'
import { useNavigate } from '../hooks/useNavigate'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { fulfillJaguarOrder } from '../libs/CloudFunctionsApiHandler'
import { onCopyText } from '../libs/onClickCopy'
import { createLeopardOrder } from '../libs/ProcessHandler'
import { Texts } from '../libs/Texts'
import Box from './Box'
import Button from './Button'
import ProgressBar from './ProgressBar'
import { VitecNextOrderItemLongWaitFigmaText } from './VitecNextOrderItemLongWaitFigmaText'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'
import { captureException } from '../libs/ErrorHelper'

export function VitecNextOrderProductButtons(props: {
  vitecNextOrder: VitecNextOrder
  vitecNextProduct: VitecNextProduct
  SuffixComponent?: React.FC
  fulfillMentSuccessText: string
  enableCopy?: boolean
}) {
  const vitecNextOrderId = props.vitecNextOrder.orderId
  const navigate = useNavigate({ enablePreserveQueryParams: true })
  const { vitecNextProduct, SuffixComponent = Box, fulfillMentSuccessText, enableCopy = true } = props
  const { data: vitecNextOrder = props.vitecNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]
  const {
    state: { enablePreview = true }
  } = useAppStateSession()

  const [requestId, setRequestId] = React.useState(leopardOrder?.requestId)
  const { data: upload } = useUpload(requestId)
  const { data: uploadedFiles } = useUploadedFiles(requestId)

  const context = useAppState()
  const { state } = context
  const [isRegenerating, setisRegenerating] = React.useState(false)
  const [isFulfilling, setIsFulfilling] = React.useState(false)
  const isLoading = isFulfilling || isRegenerating
  const { getText } = VitecNextProductInfoItems[vitecNextProduct]
  const [hasFailedFullfillment, setHasFailedFullfillment] = React.useState(false)

  if (!leopardOrder) return null

  const onClickFulfillLeopardOrder = () =>
    Promise.resolve(setIsFulfilling(true))
      .then(() =>
        fulfillJaguarOrder({ vitecNextOrderId: vitecNextOrder.orderId, requestId: leopardOrder.requestId }, state)
      )
      .catch((err) => {
        captureException(err)
        setHasFailedFullfillment(true)
      })
      .finally(() => setIsFulfilling(false))

  const onClickCreateLeopardOrder = () => {
    const requestId = createUniqueId()
    setRequestId(requestId)
    Promise.resolve(setisRegenerating(true))
      .then(() =>
        createLeopardOrder({
          vitecNextOrder,
          vitecNextProduct,
          state,
          requestId,
          enablePreview: enablePreview || false
        })
      )
      .then(() => {
        setisRegenerating(false)
        window.location.reload() // Hi future dev, for some reason basicAssociation needs this in order to show the new data, i also fear that other products are showing stale data, good luck!
      })
  }

  if (isRegenerating)
    return (
      <Container fullWidth fullPadding>
        <ProgressBar />
        {shouldShowLongWaitMessage({ upload, vitecNextProduct }) && (
          <Box top spacing={smallSpacing}>
            <VitecNextOrderItemLongWaitFigmaText vitecNextOrder={vitecNextOrder} />
          </Box>
        )}
      </Container>
    )

  return (
    <Container fullWidth fullPadding>
      <Box fullWidth direction="row" justify="space-between" align="center">
        <Box fullWidth direction="row" align="center">
          <Box>
            {!leopardOrder.fulfilledAt && (
              <Button
                buttonType="secondary"
                textKey={Texts.standardCtaUploadToNext}
                spacing={smallSpacing}
                onClick={onClickFulfillLeopardOrder}
                disabled={isRegenerating}
                loading={isFulfilling}
              />
            )}
            {leopardOrder.fulfilledAt && (
              <VitecNextOrderItemStatusView
                type="success"
                textKey={Texts.ImageTextsNotificationItCompleted}
                text={fulfillMentSuccessText}
              />
            )}
          </Box>
          <Box left>
            <Button
              buttonType="secondary"
              textKey={Texts.standardCtaRegenerate}
              spacing={smallSpacing}
              onClick={onClickCreateLeopardOrder}
              disabled={isLoading}
              loading={
                isRegenerating &&
                upload?.status !==
                  'processing' /** There is another spinner shown in parent component when upload is processing**/
              }
            />
          </Box>

          {enableCopy && (
            <Box left>
              <Button
                buttonType="secondary"
                textKey={Texts.standardCtaCopyText}
                spacing={smallSpacing}
                onClick={() => onCopyText(getText?.({ upload, uploadedFiles }))}
              />
            </Box>
          )}
        </Box>
        <SuffixComponent />
      </Box>
      {!isLoading && hasFailedFullfillment && (
        <Box fullWidth top spacing={smallSpacing}>
          <VitecNextOrderItemStatusView type="error" textKey={Texts.standardErrorVitect} />
        </Box>
      )}
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
