import { ReactComponent as warningIconImg } from './../assets/images/warningIcon.svg'
export const warningIcon = warningIconImg
import { ReactComponent as roundCheckIconWhiteImg } from './../assets/images/roundCheckIconWhite.svg'
export const roundCheckIconWhite = roundCheckIconWhiteImg
import { ReactComponent as gazelleInCircleImg } from './../assets/images/gazelleInCircle.svg'
export const gazelleInCircle = gazelleInCircleImg
import iconsImg from './../assets/images/icons.png'
export const icons = iconsImg
import { ReactComponent as imageCaptionIconImg } from './../assets/images/imageCaptionIcon.svg'
export const imageCaptionIcon = imageCaptionIconImg
import { ReactComponent as headerAndShortDescriptionIconImg } from './../assets/images/headerAndShortDescriptionIcon.svg'
export const headerAndShortDescriptionIcon = headerAndShortDescriptionIconImg
import { ReactComponent as constructionInfoIconImg } from './../assets/images/constructionInfoIcon.svg'
export const constructionInfoIcon = constructionInfoIconImg
import { ReactComponent as areaDescriptionIconImg } from './../assets/images/areaDescriptionIcon.svg'
export const areaDescriptionIcon = areaDescriptionIconImg
import { ReactComponent as adValidationIconImg } from './../assets/images/adValidationIcon.svg'
export const adValidationIcon = adValidationIconImg
import { ReactComponent as energyDeclarationIconImg } from './../assets/images/energyDeclarationIcon.svg'
export const energyDeclarationIcon = energyDeclarationIconImg
import { ReactComponent as standardIIconImg } from './../assets/images/standardIIcon.svg'
export const standardIIcon = standardIIconImg
import { ReactComponent as basicAssiciationIconImg } from './../assets/images/basicAssiciationIcon.svg'
export const basicAssiciationIcon = basicAssiciationIconImg
import feedbackIconImg from './../assets/images/feedbackIcon.png'
export const feedbackIcon = feedbackIconImg
