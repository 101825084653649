import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { VitecNextProductInfoItemLoadingProps } from '../types/VitecNextProductTypes'
import { VitecNextOrderItemStatus } from './VitecNextOrderItemStatus'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'

// This file is very similiar to VitecNextOrderItemStatusByggemate.tsx
export const VitecNextOrderItemStatusBasicAssociation = (props: VitecNextProductInfoItemLoadingProps) => {
  const { isProductEnabled } = props
  const { data: vitecNextOrder } = useVitecNOOrder(props.vitecNextOrder._id)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.BASIC_ASSOCIATION
  const requestId = leopardOrder?.requestId
  const enablePreview = leopardOrder?.enablePreview
  const { data: upload } = useUpload(requestId)

  if (!vitecNextOrder) return null

  if (isProductEnabled?.noOfDocuments === 0)
    return <VitecNextOrderItemStatusView textKey={Texts.standardNotificationMissingDocument} type="warning" />

  const successText = !enablePreview ? getFigmaText(Texts.standardNotificationUploadComplete) : undefined

  return (
    <VitecNextOrderItemStatus
      {...props}
      vitecNextOrder={vitecNextOrder}
      vitecNextProduct={VitecNextProduct.BASIC_ASSOCIATION}
      upload={upload}
      isProductEnabled={isProductEnabled}
      successText={successText}
    />
  )
}
