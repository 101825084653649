import DiffLines from './DiffLines'

export default function SandboxPage() {
  return <DiffLines base={Base} newText={Experimental} />
}

const Base = `Byggemåte

Bygning: Bygget er oppført med støpt plate fundamentert på faste masser, yttervegger er oppført i bindingsverk, isolert etter gjeldende forskrifter på oppføringstidspunktet, platelagt på innvendig side med overflatebehandling, utvendig panel som er overflatebehandlet. Saltak tekket med profilerte aluminiumsplater.

Tak: Taktekkingen er av stål/aluminiumsplater. Taket er besiktiget fra bakkenivå.

Vinduer: Bygningen har PVC vinduer med 2-lags glass. Vinduer er skiftet i 2017 unntatt 3 vinduer og en terrassedør.

Dører: Bygningen har malt hovedytterdør med glassfelt og PVC balkongdør. Innvendig har boligen malte fyllingsdører.

Trapper: Boligen har lakkert tretrapp. Det er ingen utvendige trapper nevnt i rapporten.

Balkong/terrasse: Stor ute terrasse på 108 m² med basseng og gangarealer på ca 20 m² samt terrasse utenfor soverom på 10 m² oppført i Møre Royal fra 2019.

VVS-installasjoner: Innvendige vannledninger er av plast (rør i rør). Det er avløpsrør av plast.

Ventilasjon: Boligen har naturlig ventilasjon. Det er elektrisk styrt vifte på bad.

Tekniske detaljer: Varmtvannstanken er på ca. 300 liter. Varmepumpe innsatt i 230 V anlegg med automatsikringer. Det er montert røykvarslere og pulverapparat i boligen.

Elektrisk anlegg: 230 V anlegg med automatsikringer. Det har vært el-tilsyn januar 2021, avvik er rettet iflg eier. Alt elektrisk arbeid er utført av venn som er elektriker, oppgradert inntaket til bolig utført av Hafslund.

1. Foreligger det eltilsynsrapport? Ja
2. Er det mangler på røykvarslere i boligen iht. forskriftskrav på søknadstidspunktet, men minst Byggeforskrift 1985? Nei
3. Er det skader på brannslokningsutstyr eller er apparatet eldre enn 10 år? Nei
4. Er det skader på røykvarslere? Nei
5. Foreta en helhetsvurdering av det elektriske anlegget, dets alder, allmenne tilstand og fare for liv og helse. Bør det elektriske anlegget ha en utvidet el-kontroll? Ja det foreligger ikke samsvarserklæring.
6. Eksisterer det samsvarserklæring? Nei

Tilstandsgrad

Nedenfor gjengis en kort oppsummering der den bygningssakkyndige har beskrevet avvik ved boligen som har fått tilstandsgrad 3 (TG3) eller 2 (TG2):

Ingen avvik med tilstandsgrad TG3

Følgende punkter er nevnt som TG2 og regnes som avvik som kan kreve tiltak:
- Overflater | Det er svikt i laminatgulvet foran kjøkkeninngang og flere steder i stue. Bør utbedres.
- Radon | Det er ikke foretatt radonmålinger, og bygget er heller ikke utført med radonsperre. Det bør gjennomføres radonmålinger.
- Elektrisk anlegg | Det er avvik registrert ved el-tilsyn i januar 2021, avvik er rettet iflg eier. Utvidet rapport anbefales.

Følger punkter er nevnt som IU (ikke undersøkt/ikke tilgjengelig for undersøkelse):
- Konstruksjoner ikke undersøkt | Konstruksjoner i våtrom, kjeller og bad er ikke undersøkt. Videre undersøkelser anbefales.
- Andre installasjoner | Varmepumpe innsats er ikke undersøkt. Kontroll av installasjonen anbefales.

Opplistingen over er ikke uttømmende og du oppfordres til å lese og sette deg grundig inn i alt salgsmateriell.`

const Experimental = `Byggemåte

Informasjon er hentet fra vedlagte tilstandsrapport, datert 27.09.2024.

Bygning: Bygget er oppført med støpt plate fundamenter på faste masser, yttervegger er oppført i bindingsverk, isolert etter gjeldende forskrifter på oppføringstidspunktet, platelagt på innvendig side ved overflatebehandling, utvendig panel som er overflatebehandlet. Saltak tekket med profilerte aluminiumplater. Etasjeskiller er av trebjelkelag. Bygningen har grunnmur i lettklinkerblokker. Det er såleblokk under grunnmur av blokker.

Tak: Taket er tekket av stål/aluminiumplater. Renner, nedløp og beslag er i stål/aluminiumkonstruksjon som er overflatebehandlet.

Pipe/Ildsted: Boligen har mursteinspipe og vedovn i stue og kjellerstue.

Vinduer: Bygningen har PVC vinduer med 2-lags glass. Vinduer er skiftet i 2017 unntatt 3 vinduer og en terrassedør.

Dører: Bygningen har malt hovedytterdør med glassfelt og PVC balkongdør. Innvendig har boligen malte fyllingsdører.

Trapper/adkomst: Boligen har lakkert tretrapp.

Balkong/terrasse: Stor ute terrasse på 108 m² med basseng og gangarealer på ca 20 m² samt terrasse utenfor soverom på 10 m² oppført i Møre Royal fra 2019.

VVS-installasjoner: Innvendige vannledninger er av plast (rør i rør). Det er avløpsrør av plast. Varmtvannstanken er på ca. 300 liter.

Ventilasjon: Boligen har naturlig ventilasjon. Det er kjøkkenventilator med avtrekk ut. Det er elektrisk styrt vifte i bad.

Tekniske detaljer: Det er montert røykvarslere og pulverapparat i boligen. Varmepumpe innsatt.

Elektrisk anlegg: 230 V anlegg med automatsikringer. Det har vært el-tilsyn januar 2021, avvik er rettet iflg eier. Alt elektriske arbeider er utført av venn som er elektriker, oppgradert inntaket til bolig utført av Hafslund. For at arbeider hos andre skal være lovlig må jobben gjøres og dokumenteres av registrert el virksomhet. Det er ikke gjort i dette tilfelle. Det anbefales derfor kontroll av el takstmann.
1. Foreligger det eltilsynsrapport de siste år, og det er ikke foretatt arbeid på anlegget etter denne, utenom retting av eventuelle avvik i eltilsynsrapport (dvs en el-tilsynsrapport uten avvik)? Ja
2. Er det elektriske anlegget utført eller er det foretatt tilleggsarbeider på det elektriske anlegget etter .? Ja
3. Eksisterer det samsvarserklæring? Nei
4. Foreta en helhetsvurdering av det elektriske anlegget, dets alder, allmenne tilstand og fare for liv og helse. Bør det elektriske anlegget ha en utvidet el-kontroll? Ja Det foreligger i kke samsvarserlæring.

Garasje: Garasje/bod antatt bygget i 2020 med normal standard ut fra alder/konstruksjon.


Tilstandsgrad
Nedenfor gjengis en kort oppsummering der den bygningssakkyndige har beskrevet avvik ved boligen som har fått tilstandsgrad 3 (TG3) eller 2 (TG2):

Ingen avvik med tilstandsgrad TG3

Følgende punkter er nevnt som TG2 og regnes som avvik som kan kreve tiltak:
- Overflater | Det er avvik. Svikt i laminatgulvet. Bør utbedres.
- Radon | Det er ikke foretatt radonmålinger, og bygget er heller ikke utført med radonsperre. Det bør gjennomføres radonmålinger.
- Elektrisk anlegg | Det er foretatt el-tilsyn januar 2021, avvik er rettet iflg eier. Det anbefales derfor kontroll av el takstmann.

Følger punkter er nevnt som IU (ikke undersøkt/ikke tilgjengelig for undersøkelse):
- Bad - Tilleggende konstruksjoner våtrom | Hultakking er ikke foretatt da det ikke er fysisk mulig pga tilleggende konstruksjoner.
- Andre installasjoner | Varmepumpe innsattalert. Det anbefales derfor kontroll av el takstmann.

Opplistingen over er ikke uttømmende og du oppfordres til å lese og sette deg grundig inn i alt salgsmateriell.`
