// TODO WRITE TEST, all keys and values should be same
export enum FeatureFlag {
  enableValidateAd = 'enableValidateAd',
  enableBasicAssociation = 'enableBasicAssociation',
  disableSendOnUploadInfoDone = 'disableSendOnUploadInfoDone', // disable email sendout to end customer
  enableFullfillment = 'enableFullfillment'
}

export type FeatureFlags = Record<FeatureFlag, boolean>

export type FeatureFlagEnvironment = 'default' | 'prod' | 'local' | 'test' | 'superUser'
