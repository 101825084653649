import JsonTexts from '../assets/texts.json'

export const Texts = JsonTexts

export const HardCodedTexts = {
  orderViewHeader: {
    name: 'orderViewHeader',
    characters: 'Hjelms gate 2B, Oslo',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '32px',
      fontWeight: 500,
      letterSpacing: '-0.64px',
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: '🖥️ Gazelle App Production',
    figmaTextKeyName: 'TXT_OrderViewHeader'
  },
  copyToClipBoard: {
    characters: 'Funksjoner',
    name: 'dashboardFeaturesHeader',
    texts: {
      en: 'Copied to clipboard',
      no: 'Kopiert til clipboard'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(128, 149, 230, 1)'
    },
    pageName: 'Gazelle'
  },
  vitecNextOrderProductHeaderExperimentalText: {
    characters: 'Funksjoner',
    name: 'dashboardFeaturesHeader',
    texts: {
      en: 'Normal version',
      no: 'Normal version'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(128, 149, 230, 1)'
    },
    pageName: 'Gazelle'
  },
  vitecNextOrderProductHeaderGoToExperimentalText: {
    characters: 'Funksjoner',
    name: 'dashboardFeaturesHeader',
    texts: {
      en: 'Next version',
      no: 'Next version'
    },
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(128, 149, 230, 1)'
    },
    pageName: 'Gazelle'
  }
}
