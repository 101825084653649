import dayjs from 'dayjs'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import { Upload, UploadedFile } from '../../../../pure/types/JaguarTypes'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import { isProcessingUpload, isTimedoutLeopardOrder } from '../../../../pure/libs/VitecNextOrderItemHelper'
import { VitecNextProductInfoItemLoadingProps } from '../types/VitecNextProductTypes'
import Box from './Box'
import { VitecNextOrderItemStatusView } from './VitecNextOrderItemStatusView'
import { useNavigate } from '../hooks/useNavigate'

export const VitecNextOrderItemStatus = (
  props: Partial<VitecNextProductInfoItemLoadingProps> & {
    upload?: Upload
    uploadedFiles?: UploadedFile[]
    vitecNextProduct: VitecNextProduct
    vitecNextOrder?: VitecNextOrder
    successText?: string
    now: string
  }
) => {
  const { uploadedFiles = [], successText, now } = props

  const navigate = useNavigate({ enablePreserveQueryParams: true })
  const hasFailedAllUploadedFiles =
    uploadedFiles.length > 0 && uploadedFiles.every((file) => !!file.error && !file.PropertyAdditionalInfo)

  const { upload, vitecNextOrder, vitecNextProduct } = props

  const leopardOrder = props.vitecNextOrder?.jaguarResponses?.[props.vitecNextProduct]
  const { isProductEnabled } = props

  const onClickDoneStatus = () =>
    vitecNextOrder &&
    navigate(formatRoutePathVitecNextOrderProduct({ vitecNextOrderId: vitecNextOrder._id, vitecNextProduct }))

  if (!vitecNextOrder) return <Box />

  if (!leopardOrder) return <Box />

  const { requestId } = leopardOrder

  if (isProductEnabled && !isProductEnabled?.isEnabled) return <Box />

  if (!requestId) return <Box />

  if (upload?.status === 'error')
    return <VitecNextOrderItemStatusView textKey={Texts.standardNotificationCiError} type="error" />

  if (hasFailedAllUploadedFiles)
    return <VitecNextOrderItemStatusView textKey={Texts.standardNotificationCiError} type="error" />

  switch (true) {
    case upload?.status === 'done':
      return (
        <Box link onClick={onClickDoneStatus} fullWidth>
          <VitecNextOrderItemStatusView
            textKey={Texts.standardNotificationCiCompleted}
            type="success"
            text={`${successText || getFigmaText(Texts.standardNotificationCiCompleted)} (${dayjs(
              leopardOrder.finishedAt || leopardOrder.createdAt
            ).format('DD/M HH:mm')})`}
          />
        </Box>
      )

    case isTimedoutLeopardOrder({ now, o: leopardOrder, upload }):
      return <VitecNextOrderItemStatusView type="processing" textKey={Texts.ImageTextsItJobTakingToLong} />
    case dayjs(now).diff(dayjs(leopardOrder.createdAt), 'seconds') < 10: // UX feature
      return <VitecNextOrderItemStatusView type="processing" textKey={Texts.standardNotificationInProgress} />
    case isProcessingUpload(upload):
      return <VitecNextOrderItemStatusView type="processing" textKey={Texts.standardNotificationInProgress} />
    default:
      return <Box />
  }
}
