import styled from '@emotion/styled'
import { FeatureFlag } from '../../../../pure/enums/FeatureFlags'
import Colors from '../../../../pure/libs/Colors'
import { UploadedFile } from '../../../../pure/types/JaguarTypes'
import { VitecNextOrderProductProps } from '../../../../pure/types/VitecNextOrderProductProps'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useUpload, useVitecNOOrder } from '../hooks/QueryHooks'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useUploadedFiles } from '../hooks/useUploadedFiles'
import { VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH } from '../libs/HardCodedSizes'
import Images from '../libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { LoadingPage } from './LoadingPage'
import { VitecNextOrderProductBackButton } from './VitecNextOrderProductBackButton'
import { VitecNextOrderProductButtons } from './VitecNextOrderProductButtons'
import { VitecNextOrderProductFeedbackButton } from './VitecNextOrderProductFeedbackButton'
import { VitecNextOrderProductHeader } from './VitecNextOrderProductHeader'

export const VitecNextOrderProductContructionInfo = (props: VitecNextOrderProductProps) => {
  const { vitecNextOrderId, vitecNextProduct } = props
  const { data: vitecNextOrder, isLoading } = useVitecNOOrder(vitecNextOrderId)
  const leopardOrder = vitecNextOrder?.jaguarResponses?.[vitecNextProduct]
  const { data: upload } = useUpload(leopardOrder?.requestId || '')
  const { data: uploadedFiles = [] } = useUploadedFiles(leopardOrder?.requestId || '')

  const items = getHeaderAndDescriptions(uploadedFiles)

  if (isLoading) return <LoadingPage />
  if (!vitecNextOrder) return <Box />

  return (
    <Box fullWidth fullPadding direction="row" justify="space-between" spacing={bigSpacing}>
      <Box>
        <VitecNextOrderProductBackButton {...props} />
      </Box>
      <Box fullWidth width={`${VITEC_NEXT_ORDER_PRODUCT_CONTAINER_WIDTH}px`}>
        <VitecNextOrderProductHeader
          vitecNextOrder={vitecNextOrder}
          headerTextKey={Texts.ConstructionCiHeaderText}
          vitecNextProduct={vitecNextProduct}
        />
        <Box top>
          <FigmaImageContainer imageKey={Images.gazelleInCircle} />
        </Box>
        <Box fullWidth top spacing={smallSpacing}>
          <VitecNextOrderProductButtons
            vitecNextOrder={vitecNextOrder}
            vitecNextProduct={vitecNextProduct}
            fulfillMentSuccessText={getFigmaText(Texts.standardNotificationUploadComplete)}
          />
        </Box>
        <Box top spacing={smallSpacing} />
        {upload?.status === 'processing' && <LoadingPage />}
        {upload?.status === 'done' && (
          <Container fullWidth fullPadding>
            <Box direction="row" align="center">
              <FigmaText textKey={Texts.ConstructionCiTextHeader} />
            </Box>
            <Box top>
              <VitecNextOrderProductFeedbackButton {...props} />
            </Box>
            <Box fullWidth>
              {items?.map(({ header, description }, i) => (
                <Box fullWidth key={i}>
                  <Box top fullWidth direction="row" align="center">
                    <FigmaTextWithStyle
                      Component={FigmaTextWithLinebreaks as any}
                      separator={<span />}
                      separatorWhenEmptyContent={<br />}
                      type="h7"
                      text={header}
                    />
                  </Box>
                  <Box fullWidth top>
                    {!!description && (
                      <FigmaTextWithStyle
                        Component={FigmaTextWithLinebreaks as any}
                        separator={<span />}
                        separatorWhenEmptyContent={<br />}
                        type="body1"
                        text={description}
                      />
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Container>
        )}
      </Box>
      <Box />
    </Box>
  )
}

export function getHeaderAndDescriptions(uploadedFiles: UploadedFile[]) {
  return uploadedFiles
    .reduce(
      (a, file) =>
        a.concat(
          Object.entries(file.PropertyAdditionalInfo || {})
            .filter(([k]) => k !== 'ByggemåteStructured' && k !== 'TilstandsgradStructured')
            .map(([k, v]) => ({
              header: k,
              description: v as string,
              file
            }))
        ),
      [] as any[]
    )
    .sort((a, b) => a.header.localeCompare(b.header))
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
