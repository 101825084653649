import styled from '@emotion/styled'
import Colors from '../../../../pure/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { Texts } from '../libs/Texts'
import Box from './Box'
import Button from './Button'

export function VitecNextOrderProductFeedbackViewButtons(props: { onClick }) {
  return (
    <Container fullWidth fullPadding>
      <Box fullWidth direction="row" justify="space-between" align="center">
        <Box fullWidth direction="row" align="center">
          <Box>
            <Button
              buttonType="secondary"
              textKey={Texts.standardCtaUploadToNext}
              spacing={smallSpacing}
              onClick={props.onClick}
              text="Oppdater tilbakemelding"
            />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${Colors.white};
  border-radius: ${BorderRadixes.subtle};
`
