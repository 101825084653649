import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { VitecNextProductPureInfoItems } from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import { toPx } from '../../../../pure/libs/Common'
import { formatRoutePathVitecNextOrderProduct } from '../../../../pure/libs/RoutePath'
import { TextKey } from '../../../../pure/types/Antiloop'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes'
import { tinySpacing } from '../enums/Spacings'
import { useNavigate } from '../hooks/useNavigate'
import { formatText, getFigmaText, getFigmaTextFromVariable } from '../libs/TextRepository'
import { HardCodedTexts, Texts } from '../libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'
import { InfoIcon } from './Icons'
import Tooltip from './Tooltip'

export function VitecNextOrderProductHeader(props: {
  vitecNextOrder: VitecNextOrder
  headerTextKey: TextKey
  vitecNextProduct: VitecNextProduct
  enableExperimentalVersion?: boolean
}) {
  const {
    vitecNextOrder,
    headerTextKey,
    vitecNextProduct: vitecNextOrderProduct,
    enableExperimentalVersion = true
  } = props
  const navigate = useNavigate()

  const { vitecNextProductExperimental } = VitecNextProductPureInfoItems[vitecNextOrderProduct]
  const normalVitecNextProduct = Object.entries(VitecNextProductPureInfoItems).find(
    ([k, v]) => v.vitecNextProductExperimental === vitecNextOrderProduct
  )?.[0] as VitecNextProduct
  const isExperimental = !!normalVitecNextProduct
  const headerText = getFigmaText(headerTextKey)

  return (
    <Box fullWidth>
      <Box>
        <FigmaText styleTextKey={Texts.ConstructionCiHeaderText} text={headerText} />
      </Box>
      {vitecNextOrder?.address && <FigmaText textKey={Texts.dashboardFeaturesHeader} text={vitecNextOrder?.address} />}

      {!!vitecNextProductExperimental && enableExperimentalVersion && (
        <Box
          link
          onClick={() =>
            navigate(
              formatRoutePathVitecNextOrderProduct({
                vitecNextOrderId: vitecNextOrder.orderId,
                vitecNextProduct: vitecNextProductExperimental
              })
            )
          }
        >
          <Box direction="row" align="center">
            <Box link>
              <FigmaText textKey={HardCodedTexts.vitecNextOrderProductHeaderGoToExperimentalText} />
            </Box>
            <Box left spacing={tinySpacing} link>
              <Tooltip title={formatText(getFigmaTextFromVariable('VariableID:317:936'), [headerText])}>
                <InfoIcon
                  color={HardCodedTexts.vitecNextOrderProductHeaderExperimentalText.style.color}
                  size={toPx(HardCodedTexts.vitecNextOrderProductHeaderExperimentalText.style.fontSize)}
                />
              </Tooltip>
            </Box>
          </Box>
        </Box>
      )}
      {!!isExperimental && (
        <Box
          link
          onClick={() =>
            navigate(
              formatRoutePathVitecNextOrderProduct({
                vitecNextOrderId: vitecNextOrder.orderId,
                vitecNextProduct: normalVitecNextProduct
              })
            )
          }
        >
          <FigmaText textKey={HardCodedTexts.vitecNextOrderProductHeaderExperimentalText} />
        </Box>
      )}
    </Box>
  )
}
