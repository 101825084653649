import { smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useAsyncFunction } from '../hooks/useAsyncFunction'
import { isVitecNextProductEnabled } from '../libs/CloudFunctionsApiHandler'
import { VitecNextProductInfoItem } from '../types/VitecNextProductTypes'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { VitecNextProductInfoItems } from './VitecNextProductInfoItems'

import styled from '@emotion/styled'
import { VitecNextProduct } from '../../../../pure/leopard/VitecNextProduct'
import { shouldShowLongWaitMessage } from '../../../../pure/leopard/VitecNextProductPureInfoItems'
import Colors from '../../../../pure/libs/Colors'
import { VitecNextOrder } from '../../../../pure/types/VitecNODerivedTypes.js'
import config from '../Config'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Shadows } from '../enums/Shadows'
import { useUpload } from '../hooks/QueryHooks'
import FigmaText from './FigmaText'
import { VitecNextOrderItemButton } from './VitecNextOrderItemButton'
import { VitecNextOrderItemLongWaitFigmaText } from './VitecNextOrderItemLongWaitFigmaText'

const ICON_BOX_SIZE = 100

export const VitecNextOrderItem = ({
  vitecNextProduct,
  vitecNextOrder,
  now
}: {
  vitecNextProduct: VitecNextProduct
  vitecNextOrder: VitecNextOrder
  now: string
}) => {
  const vitecNextProductInfoItem: VitecNextProductInfoItem = VitecNextProductInfoItems[vitecNextProduct]
  const { textKey, imageKey, descriptionKey, buttons, StatusComponent = () => null } = vitecNextProductInfoItem
  const leopardOrder = vitecNextOrder.jaguarResponses?.[vitecNextProduct]

  const { data: upload } = useUpload(leopardOrder?.requestId)

  const appContext = useAppState()
  const { state } = appContext
  const vitecNextOrderId = vitecNextOrder._id

  const { data: isProductEnabled, isLoading: isLoadingProduct } = useAsyncFunction(() =>
    isVitecNextProductEnabled({ vitecNextOrderId, vitecNextProduct }, state)
  )

  return (
    <Container fullWidth align="center" fullHeight>
      <Box fullWidth align="center" fullPadding spacing={tinySpacing}>
        <Box style={{ width: ICON_BOX_SIZE, height: ICON_BOX_SIZE }} align="center" justify="center">
          <FigmaImageContainer imageKey={imageKey} />
        </Box>
      </Box>
      <InnerContainer
        key={vitecNextProductInfoItem.slug}
        spacing={smallSpacing}
        fullPadding
        align="center"
        fullWidth
        fullHeight
        justify="space-between"
      >
        <Box fullWidth align="center" fullPadding spacing={smallSpacing}>
          <Box fullWidth align="center" fullHeight>
            <Box fullWidth>
              <FigmaText textKey={textKey} />
              <Box top spacing={smallSpacing}>
                <FigmaText textKey={descriptionKey} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box fullWidth>
          <Box fullWidth>
            <StatusComponent vitecNextOrder={vitecNextOrder} isProductEnabled={isProductEnabled} now={now} />
          </Box>

          {shouldShowLongWaitMessage({ vitecNextProduct, upload }) && (
            <Box top spacing={smallSpacing}>
              <VitecNextOrderItemLongWaitFigmaText vitecNextOrder={vitecNextOrder} />
            </Box>
          )}
          <Box pointer alignSelf="flex-start" direction="row" align="center" fullWidth>
            {buttons.map((button, i) => (
              <Box key={i} top spacing={smallSpacing}>
                <VitecNextOrderItemButton
                  button={button}
                  vitecNOOrder={vitecNextOrder}
                  vitecNextProduct={vitecNextProduct}
                  isProductEnabled={isProductEnabled}
                  index={i}
                  now={now}
                  isLoading={isLoadingProduct}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </InnerContainer>
    </Container>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  box-shadow: ${Shadows.large};
  width: 400px;
  background-color: ${Colors.white};
`

const InnerContainer = styled(Box)`
  background-color: ${Colors.neutral30};
  border-bottom-left-radius: ${BorderRadixes.regular};
  border-bottom-left-radius: ${BorderRadixes.regular};
`
